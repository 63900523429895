<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>List Of Images</h5>
            </div>
            <div class="card">
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="imageData" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                class="p-datatable-users" data-key="ti1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:right>
                                        <Button label="Add New Image" icon="pi pi-plus" @click="onAddImage" />
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column header="Image Name(Image/File Path)" headerStyle="width: 60%">
                                    <template #body="{ data }">
                                        <div>{{ data.ti3 }}</div>
                                    </template>
                                </Column>
                                <Column header="Uploaded Images" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <div>
                                            <span v-if="data.ti3 != null && data.ti3 != ''">
                                                <img :src="data.ti3" width="50" style="vertical-align: middle" />
                                            </span>
                                            <span v-if="data.ti3 == null || data.ti3 == ''">-</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Bucket Name" headerStyle="width: 16%">
                                    <template #body="{ data }">
                                        <div>{{ data.ti5 }}</div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditImage(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit Image Dialog starts here -->
    <Dialog v-model:visible="isDialogOpen" :style="{ width: '800px' }" :modal="true" class="p-fluid"
        :header="dialogHeader">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="image">
                                    Image
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <FileUpload mode="basic" ref="imagefile" :maxFileSize="2000000" accept="image/*"
                                    chooseLabel="Upload Image" auto @select="handleImageUpload" />
                                <small class="p-invalid p-error" v-if="imageerror">{{ imageerror
                                    }}</small>
                                <small class="p-invalid p-error" v-if="v$.imagefile.$error && imageerror == ''">{{
                                    v$.imagefile.$errors[0].$message
                                }}</small>
                                <div>
                                    <img v-if="imageUrl && imageUrl.url" class="p-pt-2" role="presentation"
                                        :alt="imageUrl.name" :src="imageUrl.url" width="120" height="120" />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="bucketNameId">
                                    Bucket name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="bucketNameId" v-model="bucketName" required="true" maxlength="30"
                                    autofocus :class="{ 'p-invalid': isSubmitted && !bucketName }" />
                                <small class="p-invalid p-error" v-if="v$.bucketName.$error">{{
                                    v$.bucketName.$errors[0].$message
                                }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                class="p-button-text p-ml-auto" @click="addImage" style="width: 90px;" :disabled="showLoader"></Button>
        </template>
    </Dialog>
    <!-- Add/Edit Image Dialog ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
    data() {
        return {
            imageData: [],
            v$: useValidate(),
            isDialogOpen: false,
            isSubmitted: false,
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            imagefile: null,
            imageUrl: null,
            dialogHeader: "",
            imageerror: '',
            ti1: 0,
            bucketName: '',
        };
    },

    validations() {
        return {
            imagefile: { required: helpers.withMessage('Please select image', required) },
            bucketName: { required: helpers.withMessage('Please enter bucket name', required) }
        };
    },

    ApiService: null,
    interval: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        this.getImageUploadDetails({ page_no: this.page_no });
    },

    methods: {
        onAddImage() {
            this.v$.$reset();
            this.xyz = "";
            this.dialogHeader = 'Add Image';
            this.isDialogOpen = true;
            this.imagefile = '';
            this.ti1 = 0;
            this.bucketName = '';
            this.imageUrl = null;
        },

        onEditImage(data) {
            this.dialogHeader = "Edit Image";
            this.isDialogOpen = true;
            this.imagefile = data.ti3;
            this.ti1 = data.ti1;
            this.bucketName = data.ti5;
            this.imageUrl = { name: data.ti2, url: data.ti3 }
        },

        async getImageUploadDetails(params) {
            try {
                this.loading = true;
                const result = await this.ApiService.getImageUploadDetails({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.imageData = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
            }
        },

        async addImage() {
            this.isSubmitted = true;
            this.v$.$validate();
            if (this.v$.error) return;
            let formData = new FormData();
            formData.append("ti1", this.ti1);
            formData.append("ti2", this.imagefile);
            formData.append("ti5", this.bucketName);
            if (!this.v$.$error) {
                this.loading = this.showLoader = true;
                let result = await this.ApiService.createUpdateUploadedImage(formData)
                this.loading = this.showLoader = this.isDialogOpen = false;
                if (result.success) {
                    this.getImageUploadDetails({ page_no: this.page_no });
                    this.imagefile = null;
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: result.message, life: 3000 });
                }
            }
        },

        handleImageUpload() {
            var imagefile = this.$refs.imagefile.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!imagefile) {
                this.imageerror = 'Please select image';
            } else if (!imagefile.size > 2048) {
                this.imageerror = 'File size cannot exceed more than 2MB';
                this.imageUrl = null;
            } else if (!imagefile.name.match(allowedExtensions)) {
                this.imageerror = 'Invalid file type';
                this.imageUrl = null;
            } else {
                this.imagefile = this.$refs.imagefile.files[0];
                this.imageUrl = { name: this.imagefile.name, url: this.imagefile.objectURL };
                this.imageerror = '';
            }
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getImageUploadDetails({ page_no: event.page });
        },
    }
};
</script>

<style scoped></style>
